"use client";
import { Notify } from "notiflix/build/notiflix-notify-aio";

const options = {
    clickToClose      : true,
    messageMaxLength  : 200,
    position          : "center-top",
    showOnlyTheLastOne: true,
    timeout           : 6000,
    width             : "350px"
};

export function showErrorNotification(message) {
    Notify.failure(message, options);
}

export function showSuccessNotification(message) {
    Notify.success(message, options);
}

export function showWarnNotification(message) {
    Notify.warning(message, options);
}
