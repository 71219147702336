const apiUrl = process.env.NEXT_PUBLIC_API_URL;
const headers = {
    "Content-Type"             : "application/json",
    "Strict-Transport-Security": "max-age=63072000; includeSubDomains; preload"
};

export async function saveUser(userId, spUser, expiresOn, mondayToken) {
    const data = { userId, spUser, expiresOn, issuedAt: new Date() };

    try {
        const response = await fetch(apiUrl + `/api/users`,
            {
                method : "PUT",
                headers: { ...headers, "Authorization": "Bearer " + mondayToken },
                body   : JSON.stringify(data)
            });
        console.log("Result of Saving to DB:", response);


        return response;
    } catch (error) {
        console.error("Save token error", error);
        return null;
    }
}

export async function getUser(userId, mondayToken) {
    const url = `${apiUrl}/api/users/${userId}`;

    try {
        const userData = await fetch(url, { headers: { ...headers, "Authorization": `Bearer ${mondayToken}` } });
        const user = await userData.json();
        if (!userData.ok) {
            console.error("Failed to fetch user", user);
            return null;
        }

        return user.result;
    } catch (error) {
        console.error("Get user error: ", error);
        return null;
    }
}

export async function deleteUser(userId, mondayToken) {
    try {
        const response = await fetch(apiUrl + `/api/users/${userId}`,
            { method: "DELETE", headers: { ...headers, "Authorization": "Bearer " + mondayToken } });

        if (!response.ok) {
            return null;
        }

        return 204;
    } catch (error) {
        console.error("Get user error: ", error);
        return 404;
    }
}
